<template>
  <div v-frag>
    <page-title title="kisokosTitle"></page-title>
    <v-container class="my-10">
      <v-row>
        <v-col cols="12">
          <div class="display-1 primary--text">
            I. Bejelentkezés és Profil
          </div>
        </v-col>
        <v-col cols="12">
          <div class="headline primary--text">
            Bejelentkezés
          </div>
        </v-col>
        <v-col cols="12">
          <p>
            A weboldalunkon, bejelentkezés után van lehetőségetek új javítási igényt leadni:
          </p>
          <v-img :src="`${publicPath}images/kisokos/01.png`" max-width="900" class="mb-6"></v-img>
          <v-img :src="`${publicPath}images/kisokos/02.png`" max-width="900" class="mb-6"></v-img>
          <p>
            A Bejelentkezés gombra kattintva automatikusan a dolgozói profilba lépsz.
          </p>
          <p>
            A bejelentkezéshez add meg céges email címed. A kezdeti jelszavad: Tmx(Törzsszámod) pl.: Tmx1234
          </p>
        </v-col>
        <v-col cols="12">
          <div class="headline primary--text">
            Dolgozói profil
          </div>
        </v-col>
        <v-col cols="12">
          <v-img :src="`${publicPath}images/kisokos/03.png`" max-width="900" class="mb-6"></v-img>
        </v-col>
        <v-col cols="12">
          <div class="headline primary--text">
            A profil főbb elemei
          </div>
        </v-col>
        <v-col cols="12">
          <v-img :src="`${publicPath}images/kisokos/04.png`" max-width="900" class="mb-6"></v-img>
          <ol>
            <li>Fejléc</li>
            <li>Kereső mező</li>
            <li>Leadott igények</li>
            <li>Menük</li>
          </ol>
        </v-col>
        <v-col cols="12">
          <div class="headline primary--text">
            Részletek
          </div>
        </v-col>
        <v-col cols="12">
          <div class="subtitle-1 font-weight-bold">
            1. Fejléc
          </div>
          <v-img :src="`${publicPath}images/kisokos/05.png`" max-width="900" class="mb-6"></v-img>
          <ol style="list-style: lower-alpha;">
            <li>Az oldalsávot összezáró gomb</li>
            <li>Automatikus kiléptetésig hátralevő idő</li>
            <li>Rendszerüzenetek</li>
            <li>Felhasználó azonosító</li>
            <li>Nyelvválasztó</li>
          </ol>
        </v-col>
        <v-col cols="12">
          <div class="subtitle-1 font-weight-bold">
            2. Kereső mező
          </div>
          <p>
            Itt bármire lehetőség van keresni a leadott igények között, például: igényazonosító, munkalapszám, gyártó, típus, státusz.
          </p>
        </v-col>
        <v-col cols="12">
          <div class="subtitle-1 font-weight-bold">
            3. Leadott igények
          </div>
          <v-img :src="`${publicPath}images/kisokos/06.png`" max-width="900" class="mb-6"></v-img>
          <p class="font-weight-bold">
            3.1 Fejléc (1):
          </p>
          <ul>
            <li>Rendelés dátuma: Az a dátum jelenik meg itt amikor a javítási igényedet leadtad.</li>
            <li>Azonosító: Az igényazonosítódat automatikusan generálja a rendszer. Ez egy fontos adat, mert ha kérdésed lenne a leadott igénnyel kapcsolatosan, akkor ez alapján fogsz tudni érdeklődni.</li>
            <li>SM munkalapszám: A munkalapszám automatikusan idekerül amint a leadott készülékedet beveszik a GSM-be, azaz amikor megkezdődik a tényleges javítási folyamat.</li>
            <li>Gyártó: Az általad kiválasztott Gyártó.</li>
            <li>Készülék típusa: A választott készülék típusa. Csak olyan készülék típusokat lesz lehetőséged kiválasztani, melyeket az adott Gyártó esetében a TMX javít.</li>
            <li>IMEI/SN: A készülék egyedi azonosítója.</li>
            <li>Állapot: A javítás aktuális státusza. Ezt kisérd figyelemmel és ha olyan státuszt látsz, amelynél tőled várunk beavatkozásra (pl.: árajánlat vagy jegyzőkönyv kezelés), akkor az igény megtekintése gomb segítségével be tudsz lépni az igényedbe és ott lehetőséged lesz a válaszod kiválasztására.</li>
          </ul>
          <p class="font-weight-bold">
            3.2 Megtekint gomb (2):
          </p>
          <p>
            A <b>MEGTEKINT</b>gombra kattintva tudsz belépni a kiválasztott igényedbe, ahol minden részletet, dokumentumot és státuszt megtalálsz.
          </p>
          <p class="font-weight-bold">
            3.3 Szervizgarancia igénylés (3):
          </p>
          <p>
            A <b>piros felkiáltójel</b> gombra kattintva igényelhetsz Szervizgaranciális ügyintézést. A kattintás után az új igény bejelentés űrlapra irányít, de már a készülék adatai ki lesznek előre töltve, amelyet nem tudsz módosítani.
          </p>
          <p class="error--text font-weight-bold">
            Figyelem: csak fizetős javítások után van lehetőséged szervizgaranciát igényelni!
          </p>
          <p>
            <b>Szervizgarancia:</b><br>
            Minden fizetős javítás után vállalunk szervizgaranciát a kicserélt alkatrészek tekintetében. Bruttó 20.000 Ft. alatt 3 hónapot, az ezt meghaladó javítások után pedig 6 hónapot. Amennyiben rendelkezik az eszköz még élő gyártói jótállással, azt a fentiek nem befolyásolják.
          </p>
          <p class="font-weight-bold">
            3.4 Oldalnézet beállítás (4):
          </p>
          <p>
            Itt lehetőséged van beállítani, hogy mennyi igényt szeretnél egyszerre látni az adott oldalon, illetve az oldalak között tudsz lapozni.
          </p>
        </v-col>
        <v-col cols="12">
          <div class="subtitle-1 font-weight-bold">
            4. Menük
          </div>
          <v-img :src="`${publicPath}images/kisokos/07.png`" max-width="360" class="mb-6"></v-img>
          <ul>
            <li><b>Kezdőlap:</b> Visszairányít a weboldalra.</li>
            <li><b>Alapadatok:</b> Ebben a menüben láthatod a profilodhoz rögzített adatokat, valamint itt tudod megváltoztatni a jelszavadat.<br>
              <i>A törzs adatokat és a pénzügyi adatokat nincsen lehetőséged módosítani, amennyiben ebben változás történt ezt jelezd a <a href="mailto:hrcsoport@tmx.hu" target="_top">hrcsoport@tmx.hu</a> és a <a href="mailto:callcenter@tmx.hu" target="_top">callcenter@tmx.hu</a> email címeken.</i></li>
            <li><b>Leadott igények:</b> Az igény listádat tudod itt megnyitni (lásd feljebb).</li>
            <li><b>Címek kezelése:</b> Itt a számlázási címedet tudod megadni / módosítani. Szállítási címet nincs módodban megváltoztatni, tekintve, hogy a Dolgozók személyesen veszik át a készülékeiket.</li>
            <li><b>Kijelentkezés:</b> Kijelentkezel a profilodból és a weboldalról.</li>
          </ul>
        </v-col>
        <v-col cols="12">
          <div class="headline primary--text">
            Igény részletek és interakciók
          </div>
          <p>
            A leadott igények részleteit és a nyomkövetést a <b>MEGTEKINT</b>gombra kattintva tudod megtekinteni:
          </p>
          <v-img :src="`${publicPath}images/kisokos/08.png`" max-width="900" class="mb-6"></v-img>
          <p><b>Követés:</b> A leadott igény szervizelési folyamatát követheted nyomon a bal oldalon látható idővonalon.</p>
          <p><b>Igény adatok:</b> A leadott készülék adatait és a hibaleírást találod a jobb oldalon.</p>
          <p><b>Ügyfél adatok:</b> Itt megtekinthető a rendszerben rögzített adataid (név, elérhetőségek, számlázási cím).</p>
        </v-col>
        <v-col cols="12">
          <div class="headline primary--text">
            Dokumentumok
          </div>
          <v-img :src="`${publicPath}images/kisokos/09.png`" max-width="900" class="mb-6"></v-img>
          <p>
            A dokumentumok alatt láthatod, megnyithatod és lementheted az általad, vagy a rendszer által feltöltött dokumentumokat (evidencia, jegyzőkönyv, árajánlat, a kiállított számla, munkalap).
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="display-1 primary--text">
            II. Új Igény és készülék leadás
          </div>
          <p>
            TMX munkavállalók csak a céges email címmel tudnak igényt leadni a dolgozói ügyintézés keretein belül.
          </p>
          <p>
            Az email címek a CRM rendszerben automatikusan rögzítésre kerülnek. Új belépőknek a HR regisztrálja a rendszerben az email címét, kilépés esetén pedig törlésre kerül.
          </p>
          <p>
            A weboldalon <a href="https://mobilfutar.tmx.hu" target="_self">https://mobilfutar.tmx.hu</a>, a céges email címeddel és a kapott jelszóval tudsz belépni a profilodba.
          </p>
          <p>
            A javítási igényedet a recepción kihelyezett tablet segítségével is leadhatod, ezt kérlek kérd az ottani kollégáktól.
          </p>
        </v-col>
        <v-col cols="12">
          <div class="headline primary--text">
            Igényleadás
          </div>
          <p>
            A belépés után a <b>ÚJ MEHRENDELÉS</b>gombra kattintva jutsz el az űrlaphoz, aminek kitöltésé után, megkezdjük a javítási folyamatot.
          </p>
        </v-col>
        <v-col cols="12">
          <div class="headline primary--text">
            Űrlap
          </div>
          <p>
            Az igényleadáshoz egy űrlapot kell kitölteni, pár adat megadásával a javítandó készülékeddel kapcsolatban.<br>
            Érdemes sorban haladni, azaz fentről kezdve, balról jobbra haladva.<br>
            Készülék adatok:
          </p>
          <v-img :src="`${publicPath}images/kisokos/10.png`" max-width="900" class="mb-6"></v-img>
          <ol>
            <li><b>Gyártó:</b>&nbsp;<span class="error--text">(kötelező)</span> A TMX által javított, vagy kezelt gyártók listáját találod itt, ezek közül tudod kiválasztani a javítandó készüléked gyártóját.</li>
            <li><b>Termék csoport:</b>&nbsp;<span class="error--text">(kötelező)</span> Az eszköz kategóriáját tudod itt kiválasztani (a megadott gyártó függvényében változik a lista).</li>
            <li><b>Készülék típus:</b>&nbsp;<span class="error--text">(kötelező)</span> A készüléked típusát / modellt tudod itt megadni (a választott Gyártó/termék csoportól függően változik a lista) a leugró listából.</li>
            <li><b>IMEI 1:</b>&nbsp;<span class="error--text">(kötelező, gyártó és típus tekintetében eltérő lehet a megadandó adat)</span> A készüléked egyedi azonosítója.</li>
            <li><b>IMEI 2:</b> (opcionális) A másodlagos azonosító.</li>
            <li><b>Széria szám-S/N:</b> (opcionális, gyártó és típus tekintetében eltérő lehet a megadandó adat) A gyári sorozatszám. (pl. Lenovo laptop/tablet esetén ez az azonosító az elsődleges).</li>
            <li><b>Hálózati zár:</b>&nbsp;<span class="error--text">(kötelező)</span> Amennyiben hálózat függő a készüléked, úgy ezt kérjük add meg a leugró listából kiválasztva.</li>
            <li><b>Garancia típus:</b>&nbsp;<span class="error--text">(kötelező)</span> Az igényelt szolgáltatás típusát, azaz Garanciális (jótálláson belül), vagy Fizetős javítást van módodban igényelni.<br>
            <span class="error--text font-weight-bold">FONTOS! Kérjük pontosan ad meg a garancia típust, mert utólagos módosításra nincsen lehetőség, csak egy új igény leadásával van lehetőséged megváltoztatni!</span></li>
            <li><b>Vásárlás dátuma:</b>&nbsp;<span class="error--text">(garanciális esetben kötelező)</span> A készülékhez kapott számlán szereplő teljesítési dátum.</li>
            <li><b>Számla:</b>&nbsp;<span class="error--text">(garanciális esetben kötelező)</span> A vásárláskor, a készülékhez kapott számlát tudod itt csatolni.</li>
            <li><b>Jótállási jegy:</b> (opcionális) A készülékhez kapott, a kereskedő által lebélyegzett jótállási jegyet tudod itt csatolni.</li>
            <li><b>Hibaleírás:</b>&nbsp;<span class="error--text">(kötelező)</span> A lehető legpontosabban add meg a készülék hibáját.</li>
          </ol>
        </v-col>
        <v-col cols="12">
          <div class="headline primary--text">
            Ügyfél, Cím és számlázási adatok
          </div>
          <v-img :src="`${publicPath}images/kisokos/11.png`" max-width="900" class="my-6"></v-img>
          <ol start="13">
            <li><b>Ügyfél adatok:</b> Ezeket az adatokat nem lesz lehetőséged módosítani. Ezt, a rendszer az előregisztrált profilodból hozza át automatikusan. Amennyiben nem megfelelő adatokat látsz itt úgy azt jelezd a hrcsoport@tmx.hu és a callcenter@tmx.hu email címen.</li>
            <li><b>Szállítási cím:</b> A szállítási címet a Dolgozói javítások során nem kell módosítani, tekintve, hogy személyes ügyintézés van.</li>
            <li><b>Számlázási cím:</b> (garanciális igénynél nem lesz látható) A számlázási címet adhatod meg itt, amely a fizetős javítás esetén a számlára fog kerülni. Ezt a leugró listából kiválaszthatod, vagy újat is adhatsz meg. A profilodban lehetőséged van a számlázási címek adminisztrációjára.</li>
            <li><b>Fizetési mód:</b> (garanciális igénynél nem lesz látható) Itt adhatod meg, hogy fizetős javítás esetén, az átvételkor bankkártyával vagy készpénzzel szeretnél fizetni.<br>
              <span class="error--text font-weight-bold">FONTOS!  - Kérjük tartsd magad az igényleadáskor általad meghatározott fizetési módhoz! A rendszer automatikusan számláz és utólagos módosításra nincsen lehetőség!</span></li>
          </ol>
          <p>
            Az adatok megadása után, kérjük olvasd el az Adatvédelmi tájékoztató, valamint az Általános Szerződési Feltételeket, majd erősítsd meg ezt a megfelelő jelölő négyzet kipipálásával:
          </p>
          <v-img :src="`${publicPath}images/kisokos/12.png`" max-width="900" class="mb-6"></v-img>
          <p>A <b>KÜLDÉS</b> gombra kattintva az igényed bekerül a CRM rendszerbe, és megkezdődik a javítási folyamat.</p>
          <p>A javításra leadandó készülékedet továbbra is a recepción kihelyezett dolgozói dobozba tudod „bedobni”.</p>
          <p>Kérjük, a kihelyezett papírra írd rá az igényszámodat, nevedet és a készülék típusát és tedd a készülék mellé az ESD tasakba.</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="display-1 primary--text">
            III. Árajánlatkezelés és jegyzőkönyvek
          </div>
          <p>
            Egy jótállásban elvégzett javítás alatt az igény és készülékleadás és az átvétel között nem lesz szükség a te beavatkozásodra, de a javítási folyamatot a profilodban nyomon követheted.
          </p>
        </v-col>
        <v-col cols="12">
          <div class="headline primary--text">
            Jegyzőkönyv
          </div>
          <p>
            Előfordulhat olyan helyzet, amikor az adott készüléket nem tudjuk a jótállás keretein belül kezelni. Például, egy beázott készülék esetében csak a szétszerelés után derül ki, hogy a beázás okozta a meghibásodást és ezt a legtöbb esetben nem lehet garanciában javítani. Ilyenkor egy jegyzőkönyv kerül kiállításra, amely megjelenik a profilodban és az email címedre egy üzenetet is kapsz erről.
          </p>
          <v-img :src="`${publicPath}images/kisokos/13.png`" max-width="900" class="mb-6"></v-img>
          <p>
            Az igényt megtekintve az alábbi felületet fogod látni:
          </p>
          <v-img :src="`${publicPath}images/kisokos/14.png`" max-width="900" class="mb-6"></v-img>
          <p>
            A jegyzőkönyv elfogadásával elfogadod a fizetős javítások feltételeit és megrendeled az árajánlatkészítést.
          </p>
          <p>
            Az elutasítás lehetőséget választva a folyamat lezárul és a készüléket visszakapod javítás nélkül.
          </p>
        </v-col>
        <v-col cols="12">
          <div class="headline primary--text">
            Árajánlatkezelés
          </div>
          <p>
            Fizetős javítások megrendelése után a készülék bevizsgálása és az árajánlat készítés után az árajánlat megjelenik a profilodban és erről email-ben is értesítünk.
          </p>
          <v-img :src="`${publicPath}images/kisokos/15.png`" max-width="900" class="mb-6"></v-img>
          <p>
            Az igényt megtekintve az alábbi válaszadó felületen jelezheted a döntésedet:
          </p>
          <v-img :src="`${publicPath}images/kisokos/16.png`" max-width="900" class="mb-6"></v-img>
          <p>Az árajánlat elfogadása után elkezdődik a javítás.</p>
          <p>Az árajánlat elutasítása után a készüléket visszakapod javítás nélkül.</p>
          <p>Az árajánlatkészítés díja mindenkinek egységesen Bruttó 2000 Ft., amit az árajánlat elutasítása esetén kell kifizetned.</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="display-1 primary--text">
            IV. Átvétel és fizetés
          </div>
          <p>
            A megjavított készülékedet a szokásos módon adhatod le a dolgozói ügyintézési időben:
          </p>
          <p>
            Ügyintézési idő: munkanapokon 13:00 – 14:00 között.
          </p>
          <p class="error--text font-weight-bold">
            Kérünk, fizetős javítás esetén az igényben megadott fizetési módhoz tartsd magad.
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import PageTitle from 'src/components/titles/PageTitle';

export default {
  name: 'Kisokos',
  components: {
    PageTitle,
  },
  props: {
    //
  },
  data() {
    return {
      //
    };
  },
  methods: {
    //
  },
};
</script>

<style scoped>

</style>
